<template>
  <v-container
    id="admin-view"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="mb-0 py-0 px-6"
        >
          <v-toolbar
            width="100%"
            flat
          >
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="register"
            >
              Register user
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col
        v-if="showAlert"
        cols="12 py-0"
      >
        <v-alert
          type="error"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-progress-circular
        v-if="loadingUsersForAllSites"
        indeterminate
        large
      />
      <template
        v-for="site in usersResponse"
        v-else
      >
        <v-col
          :key="site.siteId"
          cols="12"
          lg="6"
          class="pa-0"
        >
          <admin-data-table
            :users="site.adminableUsers.map(user => {return { ...user.user, userRole: getUserRole(user.userRole.roleId) }})"
            :site-name="site.siteName"
            :site-id="site.siteId"
            :loading-users-for-site="loadingUsersForSite"
            @removeUser="onRemoveUser"
            @updateUser="onUpdateUser"
            @addUserToSite="onAddUserToSite"
            @downloadUserData="onDownloadUserData"
          />
        </v-col>
      </template>
    </v-row>
    <v-dialog
      v-if="selectedUser"
      v-model="showUserActionDialog"
      persistent
      max-width="650"
    >
      <v-card
        v-if="removeUserFromSiteConfirmation"
        class="pa-4 px-4"
      >
        <v-card-title class="text-h5 mb-3 justify-center">
          {{ `Are you sure you want to remove ${selectedUser.user.name} from ${selectedUser.site.siteName}?` }}
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="red darken-1"
            text
            :loading="waitingForDeleteFromSiteResponse"
            @click="
              deleteUserFromSite()
            "
          >
            Delete
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            :disabled="waitingForDeleteFromSiteResponse"
            @click="
              showUserActionDialog = false
              removeUserFromSiteConfirmation= false
              selectedUser = {}
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-if="updateUserRoleConfirmation"
        class="pa-4 px-4"
      >
        <v-card-title class="text-h5 mb-3 justify-left">
          {{ `Update user role for ${selectedUser.user.name} at ${selectedUser.site.siteName}` }}
        </v-card-title>
        <div class="ml-6">
          {{ `Current role: ${selectedUser.user.userRole}` }}
        </div>
        <div class="ml-6 d-flex justify-start align-center">
          <div class="mr-2 mb-2">
            New Role:
          </div>
          <div
            style="width: 50%"
          >
            <v-select
              v-model="updateUserRoleSelection"
              :items="Object.keys(userRoles).filter(key => key != selectedUser.user.userRole )"
              dense
            />
          </div>
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="green darken-1"
            text
            :loading="waitingForUpdateUserResponse"
            :disabled="!updateUserRoleSelection"
            @click="
              updateUserRole()
            "
          >
            Update
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            :disabled="waitingForUpdateUserResponse"
            @click="
              showUserActionDialog = false
              updateUserRoleConfirmation = false
              updateUserRoleSelection = ''
              selectedUser = {}
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-if="addUserToNewSiteConfirmation"
        class="pa-4 px-4"
      >
        <template>
          <v-card-title class="text-h5 mb-3 justify-left">
            {{ `Select a user to add to ${selectedSite.siteName}` }}
          </v-card-title>
          <v-data-table
            v-model="selectedRow"
            class="available-users"
            :headers="headers"
            single-select
            item-key="email"
            :loading="waitingForAvailableUserResponse"
            :items="availableUsers"
            :fixed-header="true"
            calculate-widths
            height="500"
            @click:row="(item) => {
              addUserToSiteSelection = item
              selectedRow = [...item]
            }
            "
          >
            <template v-slot:[`body.prepend`]>
              <tr class="no-pointer">
                <td>
                  <v-text-field
                    v-model="tableName"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                    label="filter..."
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableEmail"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-alert
            v-if="getAvailableUsersError"
            type="error"
          >
            {{ alertMessageGetUsersError }}
          </v-alert>
          <v-expand-transition>
            <div
              v-if="addUserToSiteSelection"
              class="ml-6 mt-2 d-flex justify-start align-center"
            >
              <div class="text-h5 font-weight-medium mr-2 mb-2">
                Assign a role:
              </div>
              <div
                style="width: 50%"
              >
                <v-select
                  v-model="addUserRoleSelection"
                  :items="Object.keys(userRoles)"
                  dense
                />
              </div>
            </div>
          </v-expand-transition>
        </template>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="green darken-1"
            text
            :loading="waitingForAddUserResponse"
            :disabled="!addUserToSiteSelection || !addUserRoleSelection"
            @click="
              addUserRoleToUser()
            "
          >
            Add to site
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            :disabled="waitingForAddUserResponse"
            @click="
              showUserActionDialog = false
              addUserToNewSiteConfirmation = false
              addUserToSiteSelection = ''
              addUserRoleSelection = ''
              selectedSite = ''
              tableName = ''
              tableEmail = ''
              selectedRow = []
              availableUsers = []
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCsvDownloadDialog"
      persistent
      max-width="400"
    >
      <v-card class="pa-4 px-6">
        <v-card-title class="text-h5 pl-0">
          Download CSV
        </v-card-title>
        <div
          class="d-flex"
        >
          <v-text-field
            v-model="filename"
            label="Filename"
          />
          <span class="pt-8 ml-1">.csv</span>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="downloadCsv"
          >
            Save
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="showCsvDownloadDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <material-snackbar
      v-model="showSnackbar"
      type="info"
      timeout="3000"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import userApi from '@/api/user.js'
  import common from '@/api/common.js'
  import { UserRoles } from '@/util/enums'
  import { convertUserDataToCsv } from '@/util/converters'

  export default {
    name: 'Admin',
    data: () => ({
      usersResponse: [],
      selectedUser: {},
      selectedSite: {},
      adminableSites: [],

      showUserActionDialog: false,
      loadingUsersForSite: -1,
      loadingUsersForAllSites: true,

      removeUserFromSiteConfirmation: false,
      waitingForDeleteFromSiteResponse: false,

      addUserToNewSiteConfirmation: false,
      addUserToSiteSelection: '',
      selectedRow: [],
      addUserRoleSelection: '',
      waitingForAddUserResponse: false,
      availableUsers: [],
      waitingForAvailableUserResponse: false,
      getAvailableUsersError: false,
      tableName: '',
      tableEmail: '',

      updateUserRoleConfirmation: false,
      updateUserRoleSelection: '',
      userRoles: UserRoles,
      waitingForUpdateUserResponse: false,

      showCsvDownloadDialog: false,
      filename: '',
      selectedUsers: '',

      showSnackbar: false,
      snackBarMessage: '',
      snackBarMessageRemoveFromSite: 'User successfully removed from site!',
      snackBarMessageAddUserToSite: 'User successfully added to site!',
      snackBarMessageUpdateUserRole: 'User role successfully updated!',

      showAlert: false,
      alertMessageRemoveFromSiteError: 'There was an error removing the user from the site. Please try again later.',
      alertMessageAddUserToSiteErrorGeneral: 'There was an error adding the user to the site. Please try again later.',
      alertMessageAddUserToSiteError409: 'That user has been added to the site already',
      alertMessageAddUserToSiteError403: 'You do not have permission to add a user to this site',
      alertMessageUpdateUserRole: 'There was an error updating the user role. Please try again later.',
      alertMessageGetUsersError: 'There was an error retrieving users. Please try again later',
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Name',
            value: 'name',
            filter: value => {
              if (!this.tableName) return true
              return (value)?.toLowerCase().includes(this.tableName.toLowerCase())
            },
          },
          {
            text: 'Email',
            value: 'email',
            filter: value => {
              if (!this.tableEmail) return true
              return (value)?.toLowerCase().includes(this.tableEmail.toLowerCase())
            },
          },
        ]
      },
    },
    created () {
      this.getUsersForAdmin()
    },
    methods: {
      register: function () {
        this.$router.push({ path: '/admin/register/' })
      },
      getUsersForAdmin () {
        let skipFinallyBlock = false
        userApi.getUsersForAdmin()
          .then(response => {
            console.log(response)
            this.usersResponse = response.data
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.getUsersForAdmin()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageGetUsersError
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.loadingUsersForAllSites = false
              this.loadingUsersForSite = -1
            }
          })
      },

      onRemoveUser (user) {
        this.selectedUser = user
        this.showUserActionDialog = true
        this.removeUserFromSiteConfirmation = true
      },
      onUpdateUser (user) {
        this.selectedUser = user
        this.showUserActionDialog = true
        this.updateUserRoleConfirmation = true
      },
      onAddUserToSite (site) {
        this.selectedSite = site
        this.showUserActionDialog = true
        this.addUserToNewSiteConfirmation = true
        this.getUsersAvailableForSite()
      },
      onDownloadUserData (users, siteName) {
        this.showCsvDownloadDialog = true
        this.filename = siteName + '-Users'
        this.selectedUsers = users
      },

      deleteUserFromSite () {
        this.showAlert = false
        this.waitingForDeleteFromSiteResponse = true
        let skipFinallyBlock = false
        userApi.deleteUserFromSite(this.selectedUser.site.siteId, this.selectedUser.user.id)
          .then(response => {
            console.log(response)
            this.snackBarMessage = this.snackBarMessageRemoveFromSite
            this.showSnackbar = true
            this.loadingUsersForSite = this.selectedUser.site.siteId
            this.getUsersForAdmin()
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.deleteUserFromSite()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageRemoveFromSiteError
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.waitingForDeleteFromSiteResponse = false
              this.showUserActionDialog = false
              this.removeUserFromSiteConfirmation = false
              this.selectedUser = {}
            }
          })
      },
      getUserRole (roleId) {
        let role = ''
        Object.keys(UserRoles).forEach(key => {
          if (UserRoles[key] === roleId) role = key
        })
        return role
      },
      addUserRoleToUser () {
        this.showAlert = false
        this.waitingForAddUserResponse = true
        let skipFinallyBlock = false
        userApi.addUserRoleToUser({ userId: this.addUserToSiteSelection.id, roleId: UserRoles[this.addUserRoleSelection], siteId: this.selectedSite.siteId })
          .then(response => {
            console.log(response)
            this.snackBarMessage = this.snackBarMessageAddUserToSite
            this.showSnackbar = true
            this.loadingUsersForSite = this.selectedSite.siteId
            this.getUsersForAdmin()
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.addUserRoleToUser()
            } else {
              this.showAlert = true
              if (error.response?.status === 403) {
                this.alertMessage = this.alertMessageAddUserToSiteError403
              } else if (error.response?.status === 409) {
                this.alertMessage = this.alertMessageAddUserToSiteError409
              } else {
                this.alertMessage = this.alertMessageAddUserToSiteErrorGeneral
              }
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.selectedUser = {}
              this.addUserToSiteSelection = ''
              this.availableUsers = []
              this.selectedRow = []
              this.addUserRoleSelection = ''
              this.showUserActionDialog = false
              this.addUserToNewSiteConfirmation = false
              this.waitingForAddUserResponse = false
            }
          })
      },
      updateUserRole () {
        this.showAlert = false
        this.waitingForUpdateUserResponse = true
        let skipFinallyBlock = false
        userApi.updateUserRoleForUser({ userId: this.selectedUser.user.id, roleId: UserRoles[this.updateUserRoleSelection], siteId: this.selectedUser.site.siteId })
          .then(response => {
            console.log(response)
            this.snackBarMessage = this.snackBarMessageUpdateUserRole
            this.showSnackbar = true
            this.loadingUsersForSite = this.selectedUser.site.siteId
            this.getUsersForAdmin()
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.updateUserRole()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageUpdateUserRole
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.selectedUser = {}
              this.updateUserRoleSelection = ''
              this.showUserActionDialog = false
              this.updateUserRoleConfirmation = false
              this.waitingForUpdateUserResponse = false
            }
          })
      },
      getUsersAvailableForSite () {
        this.showAlert = false
        this.waitingForAvailableUserResponse = true
        this.getAvailableUsersError = false
        let skipFinallyBlock = false
        userApi.getUsersAvailableForSite(this.selectedSite.siteId)
          .then(response => {
            console.log(response)
            this.availableUsers = response.data
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.getUsersAvailableForSite()
            } else {
              this.getAvailableUsersError = true
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.waitingForAvailableUserResponse = false
            }
          })
      },

      downloadCsv () {
        const csv = convertUserDataToCsv(this.selectedUsers)
        const encodedData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        const link = document.createElement('a')

        link.setAttribute('href', encodedData)
        link.setAttribute('download', `${this.filename}.csv`)
        link.click()
        this.showCsvDownloadDialog = false
      },
    },
  }
</script>
<style css-scoped>
.available-users tr :hover {
cursor: pointer;
}
.available-users tr.no-pointer :hover {
cursor: default;
}
.available-users tr.no-pointer>td .v-input__slot :hover {
cursor: text;
}
</style>
