import axios from 'axios'
import common from '@/api/common.js'

export default {
  getUsersForAdmin () {
    const api = axios.create()
    console.log('getUsersForAdmin()')

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/user/admin/get`,
    })
  },
  deleteUserFromSite (siteId, userId) {
    const api = axios.create()
    console.log('deleteUserFromSite()', siteId, userId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/user/admin/delete/${siteId}/${userId}`,
    })
  },
  addUserRoleToUser (userRole) {
    const api = axios.create()
    console.log('addUserRoleToUser()', userRole)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/user/admin/add/userRole/${userRole.userId}`,
      data: { ...userRole },
    })
  },
  updateUserRoleForUser (userRole) {
    const api = axios.create()
    console.log('updateUserRoleForUser()', userRole)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/user/admin/update/${userRole.userId}`,
      data: { ...userRole },
    })
  },
  getUsersAvailableForSite (siteId) {
    const api = axios.create()
    console.log('updateUserRoleForUser()', siteId)

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/user/admin/get/availableForSite/${siteId}`,
    })
  },
}
